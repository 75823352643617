@import "../../styles/default.scss";

.top-bar {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  z-index: 999;
  background-color: $white;

  .logo {
    width: 200px;
  }
}
