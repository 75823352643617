@import "../../styles/default.scss";

.container-pedido {
    margin-top: 15px;
    width: 100%;

    span{
        font-weight: none;
        font-size: 17px;
        margin-bottom: 0;
    }

    small{
        color: black;
    }

    .pedido {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        h2 {
            font-size: 20px;
        }

        .cod-pedido {
            font-size: 30px;
        }

        .dia-horario {
            font-weight: normal;
            font-size: 12px;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .nome-estabelecimento {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .nome-parceiro {
            margin: 0;
        }

        .telefone {
            font-weight: normal;
            margin-bottom: 20px;
        }

        .pedido-totais-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .preco-container {
                h5 {
                    margin: 0;
                }
            }
        }

        .produtos{
            width: 50%;
            margin-bottom: 30px;
        }

        .produto-container {
            display: flex;
            padding: 3px 5px;
            margin: 3px 0;

            .produto-nome {
                margin: 0;
                padding-left: 5px;
            }

            .produto-observacao {
                padding: 2px;
                background-color: #f5f5f5;
                font-family: monospace;
                font-size: 0.9em;
                margin-top: 5px;
            }

            .produto-preco {
                font-style: italic;
                font-size: 0.8em;
                font-weight: 100;
            }

            .produto-qtd {
                font-size: 1.5em;
                top: 0px;
                align-self: center;
            }

            .imagem {
                width: 40px;
                height: 40px;
                margin: 0 3px;
            }

            .center-container {
                flex: 1;

                .produto-variacoes {
                    font-size: 11px;
                    display: flex;
                    flex-flow: wrap;
                    justify-content: space-between;

                    .produto-variacao {
                        margin: 5px;

                        ul {
                            margin: 0;
                            padding-left: 15px;

                            li {
                                list-style: none;
                            }
                        }

                        .produto-variacao-nome {
                            font-weight: bold;
                            color: $brandDark;
                            font-size: 15px;
                        }

                        .produto-opcao {
                            margin-right: 5px;
                            font-size: 0.8em;
                        }

                        .produto-opcao-nome {
                            font-weight: bold;
                            font-size: 12px;
                        }

                        .produto-opcao-preco {
                            font-style: italic;
                            font-weight: normal;
                            font-size: 12px;
                        }

                        .produto-variacao-tipo-calculo {
                            color: $brand10;
                            font-weight: bold;
                            font-size: 0.7em;
                            margin-right: 10px;
                        }
                    }
                }
            }

            .preco-container {
                text-align: right;

                span {
                    display: block;
                }

                .preco-unitario {
                    font-size: 0.8em;
                    font-weight: normal;
                }

                .preco-total {
                    font-weight: bold;
                }
            }
        }
    }
}
