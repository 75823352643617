@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

body *,
.ui.header,
input,
.iu.form input,
.ui.form input[type=text],
.ui.button {
    font-family: 'Montserrat', sans-serif;
}

$brand20:#7C08C9;
$brand10: #F7EA48;
$brand30: #E0457B;
$brandDark:#68349F;

$default10:#CFD8DC;
$default20:#455A64;
$default30:#212121;

$sutil:#F5F5F5;
$white:#ffffff;
$black:#000000;

$transparent:rgba(0, 0, 0, 0);
$hover:rgba(211, 211, 211, 0.4);

$highlight:#FF0000;



$success10:#E8F5E9;
$success20:#43a047;
$success30:#1b5e20;

$successLight:#5bcc5f;

$warning10:#FFF3E0;
$warning20:#FB8C00;
$warning30:#E65100;

$gold: #FEBF00;

$danger10:#FFEBEE;
$danger20:#E53935;
$danger30:#B71C1C;

$info10:#E3F2FD;
$info20:#1E88E5;
$info30:#0D47A1;

$brandLight:rgba(155, 77, 238 ,0.1);
$brandTransparent:rgba(155, 77, 238, 0.9);