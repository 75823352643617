@import '../../styles/default.scss';

.content-form{
    display: flex;
    flex-direction: column;

    .btns{
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .btn-outro{
        margin-top: 10px;
        font-size: 15px;
        font-weight: normal;
        cursor: pointer;
    }

    .btn-outro:hover{
        text-decoration: underline;
    }

    small {
        color: $danger30;
    }
}

.btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;

    .link{
        margin-top: 20px;
        color: black;
        cursor: pointer;
        font-size: 15px;
        font-weight: normal;
    }

    .link:hover{
        text-decoration: underline;
    }
}