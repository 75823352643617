@import './default.scss';

#root{
    width: 100%;
    height: 100%;
}

.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 20px;
}

.separator {
    height: 1px;
    background-color: $sutil;
}

span{
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
}

.container-box{
    width: 500px;
    padding: 20px;
    text-align: center;
    height: 380px;
}

.small-margin-top{
    margin-top: 20px !important;
}

.small-margin-bottom{
    margin-bottom: 20px !important;
}

.small-margin-left{
    margin-left: 20px !important;
}

.small-margin-right{
    margin-right: 20px;
}

.span-grey{
    color: grey;
}

.text-success,
.text-green {
    color: $success30 !important;
}

.text-warning {
    color: $warning20 !important;
}

.text-white {
    color: white;
}

.text-info {
    color: $info20 !important;
}

.text-danger {
    color: $danger30 !important;
}

.text-gold {
    color: $gold !important;
}

@keyframes cresce-diminui {
    10% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.05)
    }

    50% {
        transform: scale(1.1)
    }

    80% {
        transform: scale(1.05)
    }

    100% {
        transform: scale(1)
    }
}