.content-initial{
    display: flex;
    flex-direction: column;
    align-items: center;

    .btns{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin: 20px;
    }
}