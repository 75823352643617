@import "../../styles/default.scss";

.content-nota {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btns {
        margin: 20px;
    }
}

.content-comment {
    display: flex;
    flex-direction: column;

    .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;

        .link{
            margin-top: 20px;
            color: black;
            cursor: pointer;
            font-size: 15px;
            font-weight: normal;
        }

        .link:hover{
            text-decoration: underline;
        }
    }
}

small {
    color: $danger30;
}

.container-result {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .images{
        margin-top: 40px;
    }
    
    .smile {
        margin-top: 30px;
        font-size: 70px;
    }
    .coracao{
        font-size: 65px;
    }
}


.btns-mobile{
    margin-top: 10px;
}