@media only screen and (max-width: 425px) {
    .container{
        margin-top: -15px;
    }

    h2{
        font-size: 17px;
    }
    span{
        font-size: 14px;
    }

    .container-box {
        width: 100%;
        padding: 20px;
        text-align: center;
        height: 400px;
        // border: 1px;
        // border-color: black;
        // border-style: solid;
    }

    .container-pedido {
        .pedido {
            .produtos {
                width: 100%;
            }
        }
    }
}
